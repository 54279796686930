






































































import Base, { Form } from '@/application/models/Base'
import { Component, Prop, Ref } from 'vue-property-decorator'
import { QrcodeStream } from 'vue-qrcode-reader'
import { Action, Getter, namespace } from 'vuex-class'
import Hospital from '@/application/models/Hospital'
import Patient from '@/application/models/Patient'
import Tooltip from '@/components/common/Tooltip.vue'

@Component({
  components: {
    Tooltip,
    QrcodeStream
  }
})
export default class AddPatient extends Base {
  @Prop({ required: false, default: false })
  readonly bigBtn!: boolean

  // store
  @Getter('selectHospital', { namespace: 'hospitals' })
  readonly selectHospital!: Hospital

  @Action('CREATE_PATIENT', { namespace: 'patients' })
  readonly addPatient!: (patient: Patient) => void

  // controll
  dialog = false
  showScanning = false
  @Ref('form')
  readonly form!: Form

  // local data
  errorMsg = ''
  formData = {
    deviceId: '',
    patientName: '',
    patientId: ''
  }

  constructor () {
    super('addPatient')
  }

  public send () {
    if (this.form.validate()) {
      const givenNameAndSurname = this.formData.patientName.split(' ')

      this.addPatient({
        firstName: givenNameAndSurname.length >= 1 ? givenNameAndSurname[0] : undefined,
        middleName: givenNameAndSurname.length >= 3 ? givenNameAndSurname[2] : undefined,
        lastName: givenNameAndSurname.length >= 2 ? givenNameAndSurname[givenNameAndSurname.length - 1] : undefined,
        dataProviderPatientId: this.formData.patientId,
        dataProvider: {
          id: this.selectHospital.patientDataProvider!.id
        }
      })
      this.form.reset()
      this.dialog = false
    }
  }

  public close () {
    this.form.reset()
    this.dialog = false
  }

  public onDecode (decodedString: string) {
    this.formData.deviceId = decodedString
    this.showScanning = false
  }

  onInit (promise: Promise<any>) {
    this.errorMsg = ''
    promise.catch((error: any) => {
      if (error.name === 'NotAllowedError') {
        this.errorMsg = 'ERROR: you need to grant camera access permisson'
      } else if (error.name === 'NotFoundError') {
        this.errorMsg = 'ERROR: no camera on this device'
      } else if (error.name === 'NotSupportedError') {
        this.errorMsg = 'ERROR: secure context required (HTTPS, localhost)'
      } else if (error.name === 'NotReadableError') {
        this.errorMsg = 'ERROR: is the camera already in use?'
      } else if (error.name === 'OverconstrainedError') {
        this.errorMsg = 'ERROR: installed cameras are not suitable'
      } else if (error.name === 'StreamApiNotSupportedError') {
        this.errorMsg = 'ERROR: Stream API is not supported in this browser'
      }
      this.showScanning = false
    })
  }
}
